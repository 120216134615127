import React, { useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import { Typography } from '../../component/ui/foundation'
import { CardPlanItem, StepsDots } from '../../component/ui/layout'
import { PlanModel } from '../../domain/models'
import Loading from '../../component/loading/loading'
import { makePlans } from '../../main/factories/usecases/subscriptions/make-plans'
import { useHistory } from 'react-router-dom'

const SelectPlan = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<PlanModel[]>(null)
  type HistoryState = { trial: boolean }
  const historyState = useHistory().location.state as HistoryState

  useEffect(() => {
    document.title = 'GAIZ - Escolha seu plano';
    (async () => {
      try {
        const content = await makePlans()
        setData(content)
        setLoading(false)
      } catch (e) {
        setData([])
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return <Loading/>
  }

  return (
    <Box py={8}>
      <StepsDots currentDot={2} />
      <Box textAlign="center" w={['100%', '', '70%']} mx="auto" mb={12}>
        <Typography type="4xl" as="h2" mb={4}>Selecione um plano</Typography>
        <Typography type="sm" as={'p'} mb={8}>Escolha o plano que mais combina com você</Typography>
      </Box>

      <Flex flexWrap="wrap" mb={16} mx={-2} justifyContent="center">
        {data.map((planItem, index) => (
          <Box key={planItem['@id']} w={['100%', '50%', '33%', '25%']} px={2} mb={4}>
            <CardPlanItem dark={index === 0} trial={(historyState) ? historyState.trial : false} content={planItem} data={{
              description: planItem.description,
              title: planItem.name,
              image: planItem.image,
              selectable: planItem.selectable,
              totalPrice: planItem.totalPrice,
              url: `/plano/${planItem.id}`,
              intervalCount: planItem.intervalCount,
              planChange: false
            }} />
          </Box>
        ))}
      </Flex>
    </Box>
  )
}

export default SelectPlan
