import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/core'
import { Container, Typography } from '../../component/ui/foundation'
import customTheme from '../../styles/theme'
import styled from '@emotion/styled/macro'
import FolderContent from '../../component/folder-content/folder-content'
import { FolderContentModel } from '../../domain/models'
import Loading from '../../component/loading/loading'
import { makeCurrentCustomerFolderContents } from '../../main/factories/usecases/current-customer/folder-contents'
import bg0 from '../../assets/images/bgs/bg-0.jpg'
import bg1 from '../../assets/images/bgs/bg-1.jpg'
import bg2 from '../../assets/images/bgs/bg-2.jpg'

const AllTraining = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<FolderContentModel[]>(null)

  useEffect(() => {
    (async () => {
      try {
        const content = await makeCurrentCustomerFolderContents({ perPage: 30 })
        setData(content)
        setLoading(false)
      } catch (_e) {
        setData([])
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return <Loading/>
  }

  console.log(data)

  const categories = [
    { name: 'Wellness', subtitle: '', value: 1, folders: Array<FolderContentModel>() },
    { name: 'Clássicos', subtitle: 'Treinos com 45\' a 60\'', value: 2, folders: Array<FolderContentModel>() },
    { name: 'Express', subtitle: 'Treinos com 20\' a 35\'', value: 3, folders: Array<FolderContentModel>() },
    { name: 'Pdal - Pedro Paixão', subtitle: '', value: 4, folders: Array<FolderContentModel>() }
  ]

  const categoryFolder = {
    // WellNess
    '/v1/current_customer_folder_contents/11': 1,
    '/v1/current_customer_folder_contents/8': 1,
    // Clássicos
    '/v1/current_customer_folder_contents/1': 2,
    '/v1/current_customer_folder_contents/25': 2,
    '/v1/current_customer_folder_contents/7': 2,
    '/v1/current_customer_folder_contents/5': 2,
    '/v1/current_customer_folder_contents/22': 2,
    '/v1/current_customer_folder_contents/4': 2,
    '/v1/current_customer_folder_contents/6': 2,
    // Express
    '/v1/current_customer_folder_contents/3': 3,
    '/v1/current_customer_folder_contents/26': 3,
    '/v1/current_customer_folder_contents/24': 3,
    '/v1/current_customer_folder_contents/10': 3,
    '/v1/current_customer_folder_contents/23': 3,
    '/v1/current_customer_folder_contents/9': 3,
    // Pdal
    '/v1/current_customer_folder_contents/27': 4,
    '/v1/current_customer_folder_contents/28': 4,
    '/v1/current_customer_folder_contents/29': 4,
    '/v1/current_customer_folder_contents/30': 4,
    '/v1/current_customer_folder_contents/31': 4,
    '/v1/current_customer_folder_contents/32': 4,
    '/v1/current_customer_folder_contents/33': 4,
    '/v1/current_customer_folder_contents/34': 4,
    '/v1/current_customer_folder_contents/35': 4,
    '/v1/current_customer_folder_contents/36': 4,
    '/v1/current_customer_folder_contents/37': 4,
    '/v1/current_customer_folder_contents/38': 4
  }

  for (let i = 0, j = data.length; i < j; i++) {
    if (typeof categoryFolder[data[i]['@id']] !== 'undefined') {
      categories[categoryFolder[data[i]['@id']] - 1].folders.push(data[i])
    } else {
      categories[0].folders.push(data[i])
    }
  }

  const getFolders = (data: any[], categoryValue: Number) => {
    if (data && data.length > 0) {
      return data.map((item) => {
        return (
          <FolderContent item={item} view={(categoryValue === 4)} key={item['@id']}/>
        )
      })
    } else {
      return (
        <Box py={16}>
          <Container>
            <Typography type="lg" as="p">Você não possui nenhum treino ativo</Typography>
          </Container>
        </Box>
      )
    }
  }

  return (
    <>
      {
        categories.map((category) => {
          if (category.folders.length === 0) { return null } else {
            return (
              <div key={'d' + category.value}>
                <Box key={'b' + category.value} py={16}>
                  <Container key={'c' + category.value}>
                    <Typography key={'t' + category.value} type="4xl" as="h1" textAlign="center" textTransform="uppercase">{category.name}</Typography>
                    {category.subtitle !== '' &&
                                        <Typography key={'ts' + category.value} type="lg" as="h4" textAlign="center">( {category.subtitle} )</Typography>
                    }
                  </Container>
                </Box>
                <BoxFolderItems>
                  {getFolders(category.folders, category.value)}
                </BoxFolderItems>
              </div>
            )
          }
        })
      }
    </>
  )
}

const BoxFolderItems = styled.div`
  > div {
    padding: 45px 0;
    @media (min-width: ${customTheme.breakpoints[2]}) {
      padding: 90px 0;
    }
    background: ${`url(${bg0}) no-repeat top center`};
    background-size: cover;

    &:nth-of-type(odd) {
      @media (min-width: ${customTheme.breakpoints[2]}) {
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
        margin: -90px 0;
      }
    }

    &:nth-of-type(even) {
      background-image: ${`url(${bg2})`};
    }

    &:nth-of-type(5) {
      background-image: none;
      background-color: ${customTheme.colors.gray['700']};

      h2 {
        color: #ffffff;

        + div > p {
          color: #ffffff;
        }
      }
    }

    &:first-of-type {
      background-image: ${`url(${bg1})`};
    }

    &:last-of-type:nth-of-type(odd) {
      @media (min-width: ${customTheme.breakpoints[2]}) {
        margin-bottom: 0;
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
      }
    }
  }
`

export default AllTraining
